<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <div>
        <v-card-title v-if="profile" class="subtitle pt-4">Manage participant <strong class="ml-2">{{ profile.name }}</strong> (id: *{{$route.params.profileId.slice($route.params.profileId.length - 4)}})</v-card-title>
        <v-card-text>      

          <p>
            Use this tool to disconnect a user from this organization.
          </p>

          <v-alert type="info" v-if="member && member.verification_code">
            This participant has used the verification code: <strong>{{ member.verification_code }}</strong>. 
            Disconnecting this participant will release this code.
          </v-alert>
          
          <div v-if="reg">
            <h3>Registration data</h3>
            <p>This participant is linked to registration data.</p>
            Name: <strong>{{ reg.n || '-' }}</strong><br/>
            Given Name: <strong>{{ reg.gn }}</strong><br/>
            Family Name: <strong>{{ reg.fn }}</strong><br/>
            Gender: <strong>{{ reg.ge || '-' }}</strong><br/>
            Race: <strong>{{ reg.r_id || '-' }}</strong><br/>
          </div>
        </v-card-text>
          
        <v-form ref="form" class="mx-4 mb-4">
            <h3>Results</h3>
            <v-switch v-model="deleteResults" persistent-hint hint="When enabled, all results by this user will be deleted from all events by this organization." class="mt-0" label="Delete results by this user"></v-switch>
            <br/>
            <v-snackbar v-model="savedSnackbar" top color="success" :timeout="6000">This member has been disconnected from the group!</v-snackbar>
            <v-btn color="red" class="white--text" :loading="$store.getters.isLoading" @click="disconnect">Disconnect member</v-btn>
        </v-form>
        <br/>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'

export default {
  name: "Profile",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      member: null,
      reg: null,
      profile: null,
      profileId: null,
      deleteResults: false,
      savedSnackbar: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async loadData(id, profileId) {
      if (!this.org) {
        return;
      }
      this.profileId = profileId;

      const response = await orgManagerService.getMember(this.org.id, this.profileId);
      this.member = response.data.member;
      this.reg = response.data.reg;
      this.profile = response.data.profile;
    },

    async getProfile() {
      if (this.user) {
        await this.loadData(this.$route.params.id, this.$route.params.profileId);
      }
    },

    async disconnect() {
      console.log('Saving');
      var response = (await orgManagerService.disconnectMember(this.org.id, this.profileId, this.deleteResults)).data;
      console.log('Saved', response);
      if (response.status == "OK") {
        this.savedSnackbar = true;
        this.$router.push({ name: 'orgmanagerUsers', params: { id: this.org.id } });
      }
    },

    
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Users', exact: true, to: { name: 'orgmanagerUsers', params: {id: this.org.id}} },
        { text: 'Disconnect member', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

